<template>
	<div class="admin-container">
		<Head />
		<div class="banner-inn whole_000">
			<div class="container flex flex-ac ">
				<div class="banner-inn-text">
					<div class="banner-inn-tit">{{$t('labTxt')}}</div>
					<div class="banner-inn-p">
						<p class="line-2">{{$t('labTxt2')}}</p>
					</div>
				</div>
				<div class="banner-inn-pic"><img src="../../src/assets/photo/web/li-right.png" alt=""></div>
			</div>
		</div>
		<div class="admin-main admin_inn whole_fff whole_000">
			<div class="inn-hr"></div>
			<div class="container">
				<div class="inn-tab exte-box">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="$t('labTab1')" name="-1">
							<div class="is-empty" v-if="info.content && info.content.length == 0">
								{{$t('czEmpty')}}
							</div>
							<div class="inn_grid" v-else>
								<div class="inn-flex flex flex-ac" v-for="(item,index) in info.content" :key="index">
									<div class="inn-post" v-if="item.type == 1">{{$t('labType1')}}</div>
									<div class="inn-post" v-if="item.type == 2">{{$t('labType2')}}</div>
									<div class="inn-post" v-if="item.type == 3">{{$t('labType3')}}</div>
									<div class="inn-post" v-if="item.type == 4">{{$t('labType4')}}</div>
									<div class="inn-post" v-if="item.type == 5">{{$t('labType5')}}</div>
									<div class="inn-post" v-if="item.type == 6">{{$t('labType6')}}</div>
									<div class="inn-pic">
										<img :src="item.bannerImageUrl" alt="">
									</div>
									<div class="inn-pane">
										<div class="inn-hd flex flex-ac">
											<div class="inn-hd-l">
												<div class="inn-hd-tt flex flex-ac flex-zBetween">
													<div class="inn-hd-name">{{item.title}}</div>
													<div class="inn-hd-pic flex flex-ac flex-hc" v-if="item.step == 0">
														{{$t('labTab2')}}
													</div>
													<div class="inn-hd-pic inn_hd_pic1 flex flex-ac flex-hc"
														v-if="item.step == 1">{{$t('labTab3')}}</div>
													<div class="inn-hd-pic inn_hd_pic2 flex flex-ac flex-hc"
														v-if="item.step == 2">{{$t('labTab4')}}</div>
													<div class="inn-hd-pic inn_hd_pic3 flex flex-ac flex-hc"
														v-if="item.step == 3">{{$t('labTab5')}}</div>
												</div>
												<div class="inn-hd-pro">
													<div class="inn-pro-t whole-hui flex flex-ac flex-zBetween">
														<p>{{$t('labSpeen')}}</p>
														<p>{{$t('labNumber')}}</p>
													</div>
													<el-progress :percentage="item.progress" :show-text="false"
														:stroke-width='9' color="#FCD535"></el-progress>
													<div class="in-pro-ft flex flex-ac flex-zBetween">
														<p>{{item.progress.toFixed(2)}}%</p>
														<p>{{item.totalSupply.toFixed(2)}} {{item.unit}}</p>
													</div>
												</div>
											</div>
											<div class="inn-hd-r">
												<div class="inn-hd-time">{{$t('labStart')}}：{{item.startTime}}</div>
												<div class="inn-hd-time">{{$t('labEnd')}}：{{item.endTime}}</div>
											</div>
										</div>
										<div class="inn-bd flex flex-cEnd flex-zBetween">
											<div class="inn-bd-name">{{item.detail}}</div>
											<el-button  type="primary" class="btn" @click="labDetail(item.id)">
												{{$t('labDetail')}}
											</el-button>
										</div>
									</div>
								</div>
							</div>

							<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
								layout="prev, pager, next" :page-size="pageSize" :total="total" :background="true">
							</el-pagination>
						</el-tab-pane>
						<el-tab-pane :label="$t('labTab2')" name="0">
							<div class="is-empty" v-if="info1.content && info1.content.length == 0">
								{{$t('czEmpty')}}
							</div>
							<div class="inn_grid" v-else>
								<div class="inn-flex flex flex-ac" v-for="(item,index) in info1.content" :key="index">
									<div class="inn-post" v-if="item.type == 1">{{$t('labType1')}}</div>
									<div class="inn-post" v-if="item.type == 2">{{$t('labType2')}}</div>
									<div class="inn-post" v-if="item.type == 3">{{$t('labType3')}}</div>
									<div class="inn-post" v-if="item.type == 4">{{$t('labType4')}}</div>
									<div class="inn-post" v-if="item.type == 5">{{$t('labType5')}}</div>
									<div class="inn-post" v-if="item.type == 6">{{$t('labType6')}}</div>
									<div class="inn-pic">
										<img :src="item.bannerImageUrl" alt="">
									</div>
									<div class="inn-pane">
										<div class="inn-hd flex flex-ac">
											<div class="inn-hd-l">
												<div class="inn-hd-tt flex flex-ac flex-zBetween">
													<div class="inn-hd-name">{{item.title}}</div>
													<div class="inn-hd-pic flex flex-ac flex-hc" v-if="item.step == 0">
														{{$t('labTab2')}}
													</div>
													<div class="inn-hd-pic inn_hd_pic1 flex flex-ac flex-hc"
														v-if="item.step == 1">{{$t('labTab3')}}</div>
													<div class="inn-hd-pic inn_hd_pic2 flex flex-ac flex-hc"
														v-if="item.step == 2">{{$t('labTab4')}}</div>
													<div class="inn-hd-pic inn_hd_pic3 flex flex-ac flex-hc"
														v-if="item.step == 3">{{$t('labTab5')}}</div>
												</div>
												<div class="inn-hd-pro">
													<div class="inn-pro-t whole-hui flex flex-ac flex-zBetween">
														<p>{{$t('labSpeen')}}</p>
														<p>{{$t('labNumber')}}</p>
													</div>
													<el-progress :percentage="item.progress" :show-text="false"
														:stroke-width='9' color="#FCD535"></el-progress>
													<div class="in-pro-ft flex flex-ac flex-zBetween">
														<p>{{item.progress.toFixed(2)}}%</p>
														<p>{{item.totalSupply.toFixed(2)}} {{item.unit}}</p>
													</div>
												</div>
											</div>
											<div class="inn-hd-r">
												<div class="inn-hd-time">{{$t('labStart')}}：{{item.startTime}}</div>
												<div class="inn-hd-time">{{$t('labEnd')}}：{{item.endTime}}</div>
											</div>
										</div>
										<div class="inn-bd flex flex-cEnd flex-zBetween">
											<div class="inn-bd-name">{{item.detail}}</div>
											<el-button  type="primary" class="btn" @click="labDetail(item.id)">
												{{$t('labDetail')}}
											</el-button>
										</div>
									</div>
								</div>
							</div>
							<el-pagination @current-change="handleCurrentChange1" :current-page.sync="currentPage1"
								layout="prev, pager, next" :page-size="pageSize1" :total="total1" :background="true">
							</el-pagination>
						</el-tab-pane>
						<el-tab-pane :label="$t('labTab3')" name="1">
							<div class="is-empty" v-if="info2.content && info2.content.length == 0">
								{{$t('czEmpty')}}
							</div>
							<div class="inn_grid" v-else>
								<div class="inn-flex flex flex-ac" v-for="(item,index) in info2.content" :key="index">
									<div class="inn-post" v-if="item.type == 1">{{$t('labType1')}}</div>
									<div class="inn-post" v-if="item.type == 2">{{$t('labType2')}}</div>
									<div class="inn-post" v-if="item.type == 3">{{$t('labType3')}}</div>
									<div class="inn-post" v-if="item.type == 4">{{$t('labType4')}}</div>
									<div class="inn-post" v-if="item.type == 5">{{$t('labType5')}}</div>
									<div class="inn-post" v-if="item.type == 6">{{$t('labType6')}}</div>
									<div class="inn-pic">
										<img :src="item.bannerImageUrl" alt="">
									</div>
									<div class="inn-pane">
										<div class="inn-hd flex flex-ac">
											<div class="inn-hd-l">
												<div class="inn-hd-tt flex flex-ac flex-zBetween">
													<div class="inn-hd-name">{{item.title}}</div>
													<div class="inn-hd-pic flex flex-ac flex-hc" v-if="item.step == 0">
														{{$t('labTab2')}}
													</div>
													<div class="inn-hd-pic inn_hd_pic1 flex flex-ac flex-hc"
														v-if="item.step == 1">{{$t('labTab3')}}</div>
													<div class="inn-hd-pic inn_hd_pic2 flex flex-ac flex-hc"
														v-if="item.step == 2">{{$t('labTab4')}}</div>
													<div class="inn-hd-pic inn_hd_pic3 flex flex-ac flex-hc"
														v-if="item.step == 3">{{$t('labTab5')}}</div>
												</div>
												<div class="inn-hd-pro">
													<div class="inn-pro-t whole-hui flex flex-ac flex-zBetween">
														<p>{{$t('labSpeen')}}</p>
														<p>{{$t('labNumber')}}</p>
													</div>
													<el-progress :percentage="item.progress" :show-text="false"
														:stroke-width='9' color="#FCD535"></el-progress>
													<div class="in-pro-ft flex flex-ac flex-zBetween">
														<p>{{item.progress.toFixed(2)}}%</p>
														<p>{{item.totalSupply.toFixed(2)}} {{item.unit}}</p>
													</div>
												</div>
											</div>
											<div class="inn-hd-r">
												<div class="inn-hd-time">{{$t('labStart')}}：{{item.startTime}}</div>
												<div class="inn-hd-time">{{$t('labEnd')}}：{{item.endTime}}</div>
											</div>
										</div>
										<div class="inn-bd flex flex-cEnd flex-zBetween">
											<div class="inn-bd-name">{{item.detail}}</div>
											<el-button  type="primary" class="btn" @click="labDetail(item.id)">
												{{$t('labDetail')}}
											</el-button>
										</div>
									</div>
								</div>
							</div>
							<el-pagination @current-change="handleCurrentChange2" :current-page.sync="currentPage2"
								layout="prev, pager, next" :page-size="pageSize2" :total="total2" :background="true">
							</el-pagination>
						</el-tab-pane>
						<el-tab-pane :label="$t('labTab4')" name="2">
							<div class="is-empty" v-if="info3.content && info3.content.length == 0">
								{{$t('czEmpty')}}
							</div>
							<div class="inn_grid" v-else>
								<div class="inn-flex flex flex-ac" v-for="(item,index) in info3.content" :key="index">
									<div class="inn-post" v-if="item.type == 1">{{$t('labType1')}}</div>
									<div class="inn-post" v-if="item.type == 2">{{$t('labType2')}}</div>
									<div class="inn-post" v-if="item.type == 3">{{$t('labType3')}}</div>
									<div class="inn-post" v-if="item.type == 4">{{$t('labType4')}}</div>
									<div class="inn-post" v-if="item.type == 5">{{$t('labType5')}}</div>
									<div class="inn-post" v-if="item.type == 6">{{$t('labType6')}}</div>
									<div class="inn-pic">
										<img :src="item.bannerImageUrl" alt="">
									</div>
									<div class="inn-pane">
										<div class="inn-hd flex flex-ac">
											<div class="inn-hd-l">
												<div class="inn-hd-tt flex flex-ac flex-zBetween">
													<div class="inn-hd-name">{{item.title}}</div>
													<div class="inn-hd-pic flex flex-ac flex-hc" v-if="item.step == 0">
														{{$t('labTab2')}}
													</div>
													<div class="inn-hd-pic inn_hd_pic1 flex flex-ac flex-hc"
														v-if="item.step == 1">{{$t('labTab3')}}</div>
													<div class="inn-hd-pic inn_hd_pic2 flex flex-ac flex-hc"
														v-if="item.step == 2">{{$t('labTab4')}}</div>
													<div class="inn-hd-pic inn_hd_pic3 flex flex-ac flex-hc"
														v-if="item.step == 3">{{$t('labTab5')}}</div>
												</div>
												<div class="inn-hd-pro">
													<div class="inn-pro-t whole-hui flex flex-ac flex-zBetween">
														<p>{{$t('labSpeen')}}</p>
														<p>{{$t('labNumber')}}</p>
													</div>
													<el-progress :percentage="item.progress" :show-text="false"
														:stroke-width='9' color="#FCD535"></el-progress>
													<div class="in-pro-ft flex flex-ac flex-zBetween">
														<p>{{item.progress.toFixed(2)}}%</p>
														<p>{{item.totalSupply.toFixed(2)}} {{item.unit}}</p>
													</div>
												</div>
											</div>
											<div class="inn-hd-r">
												<div class="inn-hd-time">{{$t('labStart')}}：{{item.startTime}}</div>
												<div class="inn-hd-time">{{$t('labEnd')}}：{{item.endTime}}</div>
											</div>
										</div>
										<div class="inn-bd flex flex-cEnd flex-zBetween">
											<div class="inn-bd-name">{{item.detail}}</div>
											<el-button  type="primary" class="btn" @click="labDetail(item.id)">
												{{$t('labDetail')}}
											</el-button>
										</div>
									</div>
								</div>
							</div>
							<el-pagination @current-change="handleCurrentChange3" :current-page.sync="currentPage3"
								layout="prev, pager, next" :page-size="pageSize3" :total="total3" :background="true">
							</el-pagination>
						</el-tab-pane>
						<el-tab-pane :label="$t('labTab5')" name="3">
							<div class="is-empty" v-if="info4.content && info4.content.length == 0">
								{{$t('czEmpty')}}
							</div>
							<div class="inn_grid" v-else>
								<div class="inn-flex flex flex-ac" v-for="(item,index) in info4.content" :key="index">
									<div class="inn-post" v-if="item.type == 1">{{$t('labType1')}}</div>
									<div class="inn-post" v-if="item.type == 2">{{$t('labType2')}}</div>
									<div class="inn-post" v-if="item.type == 3">{{$t('labType3')}}</div>
									<div class="inn-post" v-if="item.type == 4">{{$t('labType4')}}</div>
									<div class="inn-post" v-if="item.type == 5">{{$t('labType5')}}</div>
									<div class="inn-post" v-if="item.type == 6">{{$t('labType6')}}</div>
									<div class="inn-pic">
										<img :src="item.bannerImageUrl" alt="">
									</div>
									<div class="inn-pane">
										<div class="inn-hd flex flex-ac">
											<div class="inn-hd-l">
												<div class="inn-hd-tt flex flex-ac flex-zBetween">
													<div class="inn-hd-name">{{item.title}}</div>
													<div class="inn-hd-pic flex flex-ac flex-hc" v-if="item.step == 0">
														{{$t('labTab2')}}
													</div>
													<div class="inn-hd-pic inn_hd_pic1 flex flex-ac flex-hc"
														v-if="item.step == 1">{{$t('labTab3')}}</div>
													<div class="inn-hd-pic inn_hd_pic2 flex flex-ac flex-hc"
														v-if="item.step == 2">{{$t('labTab4')}}</div>
													<div class="inn-hd-pic inn_hd_pic3 flex flex-ac flex-hc"
														v-if="item.step == 3">{{$t('labTab5')}}</div>
												</div>
												<div class="inn-hd-pro">
													<div class="inn-pro-t whole-hui flex flex-ac flex-zBetween">
														<p>{{$t('labSpeen')}}</p>
														<p>{{$t('labNumber')}}</p>
													</div>
													<el-progress :percentage="item.progress" :show-text="false"
														:stroke-width='9' color="#FCD535"></el-progress>
													<div class="in-pro-ft flex flex-ac flex-zBetween">
														<p>{{item.progress.toFixed(2)}}%</p>
														<p>{{item.totalSupply.toFixed(2)}} {{item.unit}}</p>
													</div>
												</div>
											</div>
											<div class="inn-hd-r">
												<div class="inn-hd-time">{{$t('labStart')}}：{{item.startTime}}</div>
												<div class="inn-hd-time">{{$t('labEnd')}}：{{item.endTime}}</div>
											</div>
										</div>
										<div class="inn-bd flex flex-cEnd flex-zBetween">
											<div class="inn-bd-name">{{item.detail}}</div>
											<el-button  type="primary" class="btn" @click="labDetail(item.id)">
												{{$t('labDetail')}}
											</el-button>
										</div>
									</div>
								</div>
							</div>
							<el-pagination @current-change="handleCurrentChange4" :current-page.sync="currentPage4"
								layout="prev, pager, next" :page-size="pageSize4" :total="total4" :background="true">
							</el-pagination>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		pageQuery
	} from '@/api/api/active'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				activeName: '-1',
				InnList: [{
						post: "云矿机认购",
						name: "WERT",
						text: "即将开始",
						block: 1,
						shu: 0,
						progress: 0,
						totalSupply: 5000000,
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "限时抢购",
						text: "进行中",
						block: 2,
						shu: 40,
						bfb: 0,
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "已完成",
						block: 3,
						shu: 40,
						bfb: 0,
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "派发中",
						block: 4,
						shu: 40,
						bfb: 0,
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					}
				],
				InnList1: [{
						post: "云矿机认购",
						name: "WERT",
						text: "即将开始",
						block: 1,
						shu: 0,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
						type: 1
					},
					{
						post: "云矿机认购",
						name: "WERT",
						text: "即将开始",
						block: 1,
						shu: 0,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
						type: 2
					},
					{
						post: "云矿机认购",
						name: "WERT",
						text: "即将开始",
						block: 1,
						shu: 0,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
						type: 3
					},
					{
						post: "云矿机认购",
						name: "WERT",
						text: "即将开始",
						block: 1,
						shu: 0,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
						type: 4
					}
				],
				InnList2: [{
						post: "自由认购",
						name: "限时抢购",
						text: "进行中",
						bannerImageUrl: '',
						block: 2,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "限时抢购",
						text: "进行中",
						bannerImageUrl: '',
						block: 2,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "限时抢购",
						text: "进行中",
						bannerImageUrl: '',
						block: 2,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "限时抢购",
						text: "进行中",
						block: 2,
						bannerImageUrl: '',
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					}
				],
				InnList3: [{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "派发中",
						block: 4,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "派发中",
						block: 4,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "派发中",
						block: 4,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "派发中",
						block: 4,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					}
				],
				InnList4: [{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "已完成",
						block: 3,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "已完成",
						block: 3,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "已完成",
						block: 3,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					},
					{
						post: "自由认购",
						name: "币严合伙人云矿机(4A)",
						text: "已完成",
						block: 3,
						shu: 40,
						bfb: "0.00%",
						number: "5000000.00 ARN",
						timego: "2023-04-20 00:00:00",
						timeover: "2023-04-26 00:00:00",
						tit: "SDFGH",
					}
				],
				currentPage: 1,
				pageSize: 10,
				total: 10,
				currentPage1: 1,
				pageSize1: 10,
				total1: 10,
				currentPage2: 1,
				pageSize2: 10,
				total2: 10,
				currentPage3: 1,
				pageSize3: 10,
				total3: 10,
				currentPage4: 1,
				pageSize4: 10,
				total4: 10,
				info: {},
				info1: {},
				info2: {},
				info3: {},
				info4: {}

			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			async getList() {
				if (this.activeName == '-1') {
					let options = {
						pageNo: this.currentPage,
						pageSize: this.pageSize,
						step: this.activeName
					}
					const res = await pageQuery(options)
					if (res.code == 0) {
						this.info = res.data
						this.total = res.data.totalElements
					}

				} else if (this.activeName == '0') {
					let options = {
						pageNo: this.currentPage1,
						pageSize: this.pageSize1,
						step: this.activeName
					}
					const res = await pageQuery(options)
					if (res.code == 0) {
						this.info1 = res.data
						this.total1 = res.data.totalElements
					}
				} else if (this.activeName == '1') {
					let options = {
						pageNo: this.currentPage2,
						pageSize: this.pageSize2,
						step: this.activeName
					}
					const res = await pageQuery(options)
					if (res.code == 0) {
						this.info2 = res.data
						this.total2 = res.data.totalElements
					}
				} else if (this.activeName == '2') {
					let options = {
						pageNo: this.currentPage3,
						pageSize: this.pageSize3,
						step: this.activeName
					}
					const res = await pageQuery(options)
					if (res.code == 0) {
						this.info3 = res.data
						this.total3 = res.data.totalElements
					}
				} else {
					let options = {
						pageNo: this.currentPage4,
						pageSize: this.pageSize4,
						step: this.activeName
					}
					const res = await pageQuery(options)
					if (res.code == 0) {
						this.info4 = res.data
						this.total4 = res.data.totalElements
					}
				}

			},
			handleClick(tab) {
				this.activeName = tab.name
				this.getList()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			handleCurrentChange1(val) {
				this.currentPage1 = val
				this.getList()
			},
			handleCurrentChange2(val) {
				this.currentPage2 = val
				this.getList()
			},
			handleCurrentChange3(val) {
				this.currentPage3 = val
				this.getList()
			},
			handleCurrentChange4(val) {
				this.currentPage4 = val
				this.getList()
			},
			labDetail(id) {
				this.$router.push({
					path: '/lab/detail/' + id
				})
			}
		}
	}
</script>
